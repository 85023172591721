$(function(){
	//アコーディオン
	$('.accordion-btn').click(function(){
		$(this).toggleClass('active');
		$(this).prev('.accordion-body').slideToggle();
	});
	//スムーズスクロール
  $('a[href^="#"]').click(function(){
    var adjust = 0;
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top + adjust;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
  
  // gnav
  $(".gnav-btn").click(function () {
    $(this).toggleClass('active');
    $("#gnav").toggleClass('panelactive');
    $(".gnav-btn").toggleClass('panelactive');
  });
  // $(".gnav-btn__close").click(function () {
  //   $(this).toggleClass('active');
  //   $("#gnav").removeClass('panelactive');
  //   $(".gnav-btn").removeClass('panelactive');
  // });
});
